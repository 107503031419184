import backgroundImage from "../assets/examples/background.png";
import subjectImage from "../assets/examples/subject.png";

export const defaultContent = {
  backgroundImage,
  subjectImage,
  textConfig: {
    text: "SMILE",
    font: "Righteous",
    fontSize: 241,
    fontWeight: "normal",
    x: 50,
    y: 21,
    color: "#f26969",
    shadowColor: "#746767",
    fontStyle: "normal",
    textDecoration: "none",
    animationOrder: 0,
  },
};
