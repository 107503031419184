import React from "react";
import { Upload } from "lucide-react";

interface ImageUploaderProps {
  onImageSelect: (file: File) => void;
  isProcessing: boolean;
}

export function ImageUploader({
  onImageSelect,
  isProcessing,
}: ImageUploaderProps) {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onImageSelect(file);
    }
  };

  return (
    <div className="w-full">
      <label
        className={`flex flex-col items-center justify-center w-full h-40 border-2 border-dashed rounded-lg cursor-pointer transition-colors
          ${
            isProcessing
              ? "bg-gray-100 border-gray-300 cursor-not-allowed"
              : "border-blue-300 hover:bg-blue-50 hover:border-blue-400"
          }`}
      >
        <div className="flex flex-col items-center justify-center pt-4 pb-4">
          <Upload
            className={`w-8 h-8 mb-2 ${isProcessing ? "text-gray-400" : "text-blue-500"}`}
          />
          <p
            className={`mb-1 text-sm ${isProcessing ? "text-gray-500" : "text-blue-500"}`}
          >
            {isProcessing ? (
              <span className="flex items-center gap-2">
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Processing ...
              </span>
            ) : (
              "Click or drag to upload an image"
            )}
          </p>
          <p className="text-xs text-gray-500">PNG, JPG or WEBP (Max 5MB)</p>
        </div>
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
          disabled={isProcessing}
        />
      </label>
    </div>
  );
}
