export interface FontConfig {
  name: string;
  family: string;
  url: string;
  weights: {
    normal?: string;
    bold?: string;
    italic?: string;
  };
}

export const fonts: FontConfig[] = [
  {
    name: "Abril Fatface",
    family: "Abril Fatface",
    url: "https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Alfa Slab One",
    family: "Alfa Slab One",
    url: "https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Asset",
    family: "Asset",
    url: "https://fonts.googleapis.com/css2?family=Asset&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Bebas Neue",
    family: "Bebas Neue",
    url: "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Bevan",
    family: "Bevan",
    url: "https://fonts.googleapis.com/css2?family=Bevan&display=swap",
    weights: {
      normal: "400",
      italic: "400",
    },
  },
  {
    name: "Black Ops One",
    family: "Black Ops One",
    url: "https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Bungee Inline",
    family: "Bungee Inline",
    url: "https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Bungee Outline",
    family: "Bungee Outline",
    url: "https://fonts.googleapis.com/css2?family=Bungee+Outline&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Bungee Shade",
    family: "Bungee Shade",
    url: "https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Bungee Spice",
    family: "Bungee Spice",
    url: "https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Bungee Tint",
    family: "Bungee Tint",
    url: "https://fonts.googleapis.com/css2?family=Bungee+Tint&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Cherry Bomb One",
    family: "Cherry Bomb One",
    url: "https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Coiny",
    family: "Coiny",
    url: "https://fonts.googleapis.com/css2?family=Coiny&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Dancing Script",
    family: "Dancing Script",
    url: "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap",
    weights: {
      normal: "400",
      bold: "700",
    },
  },
  {
    name: "Honk",
    family: "Honk",
    url: "https://fonts.googleapis.com/css2?family=Honk&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Lora",
    family: "Lora",
    url: "https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400&display=swap",
    weights: {
      normal: "400",
      bold: "700",
      italic: "400",
    },
  },
  {
    name: "Montserrat",
    family: "Montserrat",
    url: "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap",
    weights: {
      normal: "400",
      bold: "700",
      italic: "400",
    },
  },
  {
    name: "Oswald",
    family: "Oswald",
    url: "https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap",
    weights: {
      normal: "400",
      bold: "700",
    },
  },
  {
    name: "Pacifico",
    family: "Pacifico",
    url: "https://fonts.googleapis.com/css2?family=Pacifico&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Permanent Marker",
    family: "Permanent Marker",
    url: "https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Playfair Display",
    family: "Playfair Display",
    url: "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap",
    weights: {
      normal: "400",
      bold: "700",
      italic: "400",
    },
  },
  {
    name: "Protest Guerrilla",
    family: "Protest Guerrilla",
    url: "https://fonts.googleapis.com/css2?family=Protest+Guerrilla&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Quicksand",
    family: "Quicksand",
    url: "https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap",
    weights: {
      normal: "400",
      bold: "700",
    },
  },
  {
    name: "Righteous",
    family: "Righteous",
    url: "https://fonts.googleapis.com/css2?family=Righteous&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Roboto",
    family: "Roboto",
    url: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap",
    weights: {
      normal: "400",
      bold: "700",
      italic: "400",
    },
  },
  {
    name: "Saira Stencil One",
    family: "Saira Stencil One",
    url: "https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Shadows Into Light",
    family: "Shadows Into Light",
    url: "https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Sigmar",
    family: "Sigmar",
    url: "https://fonts.googleapis.com/css2?family=Sigmar&display=swap",
    weights: {
      normal: "400",
    },
  },
  {
    name: "Sigmar One",
    family: "Sigmar One",
    url: "https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap",
    weights: {
      normal: "400",
    },
  },
];
