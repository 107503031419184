import { removeBackground } from "@imgly/background-removal";
import {
  ChevronDown,
  ChevronUp,
  Download,
  Play,
  Plus,
  Trash,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { AnimationPreview, TextConfig } from "./components/AnimationPreview";
import { ImageUploader } from "./components/ImageUploader";
import { Select } from "./components/ui/Select";
import { defaultContent } from "./config/defaults";
import { fonts } from "./config/fonts";

function App() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedImage, setProcessedImage] = useState<string | null>(
    defaultContent.subjectImage,
  );
  const [originalImage, setOriginalImage] = useState<string | null>(
    defaultContent.backgroundImage,
  );
  const [animationUrl, setAnimationUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [textConfigs, setTextConfigs] = useState<TextConfig[]>([
    defaultContent.textConfig,
  ]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState<Record<number, boolean>>(
    { 0: true },
  );
  const [selectedTextIndex, setSelectedTextIndex] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleImageSelect = async (file: File) => {
    try {
      setIsProcessing(true);
      setError(null);
      setProcessedImage(null);
      setAnimationUrl(null);

      // Validate file size
      if (file.size > 5 * 1024 * 1024) {
        throw new Error("Image size should be less than 5MB");
      }

      // Validate file type
      if (!file.type.startsWith("image/")) {
        throw new Error("Please upload a valid image file");
      }
      const originalImageUrl = URL.createObjectURL(file);
      setOriginalImage(originalImageUrl);

      // Process image with background removal
      const blob = await removeBackground(file, {
        debug: true,
        device: "cpu",
        proxyToWorker: true,
        model: "isnet_fp16",
      });

      const url = URL.createObjectURL(blob);
      setProcessedImage(url);
    } catch (err) {
      console.error("Error processing image:", err);
      setError(
        err instanceof Error
          ? err.message
          : "Failed to process image. Please try again with a different image.",
      );
      setProcessedImage(null);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAnimationComplete = (blob: Blob) => {
    setIsAnimating(false);
    // Clean up previous URL if it exists
    if (animationUrl) {
      URL.revokeObjectURL(animationUrl);
    }

    const url = URL.createObjectURL(blob);
    setAnimationUrl(url);
  };

  const handleDownloadImage = () => {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      const link = document.createElement("a");
      link.download = "processed-image.png";
      link.href = canvas.toDataURL();
      link.click();
    }
  };

  // Clean up URL when component unmounts
  useEffect(() => {
    return () => {
      if (animationUrl) {
        URL.revokeObjectURL(animationUrl);
      }
    };
  }, []);

  useEffect(() => {
    // Load fonts and ensure they're ready before rendering
    const fontPromises = fonts.map((font) => {
      const link = document.createElement("link");
      link.href = font.url;
      link.rel = "stylesheet";
      document.head.appendChild(link);
      return document.fonts.ready;
    });

    Promise.all(fontPromises).then(() => {
      // Force a re-render after fonts are loaded
      setTextConfigs((prev) => prev.map((config) => ({ ...config })));
    });
  }, []);

  const addTextConfig = () => {
    const newIndex = textConfigs.length;

    // Use the configuration from the most recently selected text component
    const sourceConfig = textConfigs[selectedTextIndex];

    // Create new config based on the selected config
    const newConfig = {
      ...sourceConfig,
      text: `Text ${newIndex + 1}`,
      // Slightly adjust position to make the new text visible
      x: Math.min(100, sourceConfig.x + 5),
      y: Math.min(100, sourceConfig.y + 5),
      animationOrder: newIndex, // Set animation order to match the index
    };

    setTextConfigs((prev) => [...prev, newConfig]);
    setSelectedTextIndex(newIndex);

    // Collapse all panels and expand only the new one
    const newExpandedState: Record<number, boolean> = {};
    textConfigs.forEach((_, idx) => {
      newExpandedState[idx] = false;
    });
    newExpandedState[newIndex] = true;
    setExpandedPanels(newExpandedState);

    // Clear animation URL when adding new text
    if (animationUrl) {
      URL.revokeObjectURL(animationUrl);
      setAnimationUrl(null);
    }

    // Scroll to the newly added text config after a brief delay
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
    }, 100);
  };

  const removeTextConfig = (index: number) => {
    const newConfigs = textConfigs.filter((_, i) => i !== index);

    // Update animation order for remaining items
    const reorderedConfigs = newConfigs.map((config, idx) => ({
      ...config,
      animationOrder: idx,
    }));

    setTextConfigs(reorderedConfigs);

    // Update selected text index if the removed one was selected
    if (selectedTextIndex === index) {
      setSelectedTextIndex(Math.max(0, index - 1));
    } else if (selectedTextIndex > index) {
      setSelectedTextIndex(selectedTextIndex - 1);
    }

    // Update expanded panels state
    const newExpandedState: Record<number, boolean> = {};
    Object.keys(expandedPanels).forEach((key) => {
      const keyNum = parseInt(key);
      if (keyNum < index) {
        newExpandedState[keyNum] = expandedPanels[keyNum];
      } else if (keyNum > index) {
        newExpandedState[keyNum - 1] = expandedPanels[keyNum];
      }
    });

    // Make sure at least one panel is expanded
    if (
      newConfigs.length > 0 &&
      Object.values(newExpandedState).every((v) => !v)
    ) {
      newExpandedState[Math.max(0, index - 1)] = true;
    }

    setExpandedPanels(newExpandedState);

    // Clear animation URL when removing text
    if (animationUrl) {
      URL.revokeObjectURL(animationUrl);
      setAnimationUrl(null);
    }
  };

  const updateTextConfig = (index: number, updates: Partial<TextConfig>) => {
    setTextConfigs((prev) =>
      prev.map((config, i) =>
        i === index ? { ...config, ...updates } : config,
      ),
    );

    // Clear animation URL when text config changes
    if (animationUrl) {
      URL.revokeObjectURL(animationUrl);
      setAnimationUrl(null);
    }
  };

  const handleTextPositionChange = (index: number, x: number, y: number) => {
    updateTextConfig(index, { x, y });
  };

  const changeAnimationOrder = (index: number, newOrder: number) => {
    // Make sure the order is valid
    if (newOrder < 0 || newOrder >= textConfigs.length) return;

    const updatedConfigs = [...textConfigs];
    updatedConfigs[index] = {
      ...updatedConfigs[index],
      animationOrder: newOrder,
    };

    setTextConfigs(updatedConfigs);

    // Clear animation URL when animation order changes
    if (animationUrl) {
      URL.revokeObjectURL(animationUrl);
      setAnimationUrl(null);
    }
  };

  const togglePanel = (index: number) => {
    // Collapse all panels and expand only the selected one
    const newExpandedState: Record<number, boolean> = {};
    textConfigs.forEach((_, idx) => {
      newExpandedState[idx] = idx === index ? !expandedPanels[index] : false;
    });
    setExpandedPanels(newExpandedState);

    // Update selected text index
    setSelectedTextIndex(index);
  };

  const handleTextClick = (index: number) => {
    setSelectedTextIndex(index);
    togglePanel(index);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="min-h-screen flex flex-col">
        <div className="text-center py-4 px-4">
          <h1 className="text-4xl md:text-3xl sm:text-2xl font-bold text-gray-900 mb-2">
            Animate Any Image
          </h1>
          <p className="text-lg sm:text-base text-gray-600">
            Inspired by{" "}
            <a
              href="https://textbehindimage.rexanwong.xyz/"
              target="_blank"
              className="text-blue-600 font-semibold"
            >
              Text Behind Image
            </a>
            .
          </p>
          <p className="text-sm text-gray-600">
            Made with ❤️ by{" "}
            <a
              href="https://vipro.studio"
              target="_blank"
              className="text-blue-600 font-semibold"
            >
              Vipro Studio Team
            </a>
          </p>
        </div>

        {error && (
          <div className="max-w-md mx-auto mb-2 p-4 mx-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
            {error}
          </div>
        )}

        <div className="flex-1 flex flex-col lg:flex-row gap-6 p-4 md:p-6">
          {/* Left Panel - Preview */}
          <div className="flex-1 flex flex-col bg-white rounded-xl shadow-lg p-4 md:p-6">
            {/* Header with title and buttons */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-3">
              <h2 className="text-xl font-semibold">Preview</h2>

              {processedImage && (
                <div className="flex flex-wrap gap-2 w-full sm:w-auto">
                  <button
                    onClick={handleDownloadImage}
                    className="flex items-center justify-center gap-2 px-3 py-2 bg-gray-600 text-white text-sm rounded-lg hover:bg-gray-700 transition-colors"
                  >
                    <Download className="w-4 h-4" />
                    Download Image
                  </button>

                  <button
                    onClick={() => setIsAnimating(true)}
                    disabled={isAnimating}
                    className="flex items-center justify-center gap-2 px-3 py-2 bg-blue-600 text-white text-sm rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-300"
                  >
                    <Play className="w-4 h-4" />
                    {animationUrl ? "Replay" : "Animate"}
                  </button>

                  {animationUrl && (
                    <a
                      href={animationUrl}
                      download="animation.webm"
                      className="flex items-center justify-center gap-2 px-3 py-2 bg-green-600 text-white text-sm rounded-lg hover:bg-green-700 transition-colors"
                    >
                      <Download className="w-4 h-4" />
                      Download Animation
                    </a>
                  )}
                </div>
              )}
            </div>

            {/* Preview content */}
            <div
              className="flex-1"
              style={{ minHeight: "350px", maxHeight: "calc(100vh - 200px)" }}
            >
              {processedImage ? (
                <div className="h-full">
                  <AnimationPreview
                    originalImage={originalImage}
                    processedImage={processedImage}
                    isAnimating={isAnimating}
                    textConfigs={textConfigs}
                    selectedTextIndex={selectedTextIndex}
                    onTextClick={handleTextClick}
                    onAnimationComplete={handleAnimationComplete}
                    onTextPositionChange={handleTextPositionChange}
                  />
                </div>
              ) : (
                <div className="h-full flex items-center justify-center border-2 border-dashed border-gray-200 rounded-lg">
                  <p className="text-gray-500">Preview will appear here</p>
                </div>
              )}
            </div>
          </div>

          {/* Right Panel - Upload & Configure */}
          <div className="w-full lg:w-[400px] flex flex-col">
            <div className="bg-white rounded-xl shadow-lg p-4 md:p-6 flex flex-col">
              <h2 className="text-xl font-semibold mb-4">Upload & Configure</h2>
              {/* Fix for the height - use a fixed and overflow container pattern */}
              <div className="h-[calc(100vh-200px)] flex flex-col">
                <div className="mb-4 flex-shrink-0">
                  <ImageUploader
                    onImageSelect={handleImageSelect}
                    isProcessing={isProcessing}
                  />
                </div>

                {processedImage && (
                  <div
                    ref={scrollContainerRef}
                    className="overflow-y-auto flex-grow"
                  >
                    <div className="mb-4 space-y-4">
                      {textConfigs.map((textConfig, index) => {
                        const selectedFont =
                          fonts.find((f) => f.family === textConfig.font) ||
                          fonts[0];
                        const isExpanded = expandedPanels[index] || false;
                        const isSelected = selectedTextIndex === index;

                        return (
                          <div
                            key={index}
                            className={`overflow-hidden rounded-lg ${
                              isSelected
                                ? "ring-blue-400 border border-1"
                                : "border border-gray-200"
                            }`}
                          >
                            {/* Header - Always visible */}
                            <div
                              className={`p-3 flex justify-between items-center cursor-pointer 
                                ${isSelected ? "bg-blue-50 hover:bg-blue-100" : "hover:bg-gray-50"}`}
                              onClick={() => togglePanel(index)}
                            >
                              <div className="flex items-center gap-2">
                                {isExpanded ? (
                                  <ChevronUp className="w-4 h-4 text-gray-500" />
                                ) : (
                                  <ChevronDown className="w-4 h-4 text-gray-500" />
                                )}
                                <h3 className="text-lg font-medium">
                                  Text {index + 1}
                                </h3>
                                <span className="text-sm text-gray-500 truncate max-w-[120px]">
                                  {textConfig.text}
                                </span>
                              </div>
                              <div className="flex items-center gap-2">
                                <div className="flex items-center">
                                  <label className="text-sm text-gray-600 mr-2">
                                    Order:
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    max={textConfigs.length - 1}
                                    className="w-16 p-1 border rounded"
                                    value={textConfig.animationOrder || index}
                                    onChange={(e) => {
                                      e.stopPropagation(); // Prevent panel toggle
                                      changeAnimationOrder(
                                        index,
                                        Number(e.target.value),
                                      );
                                    }}
                                    onClick={(e) => e.stopPropagation()} // Prevent panel toggle
                                  />
                                </div>
                                {textConfigs.length > 1 && (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent panel toggle
                                      removeTextConfig(index);
                                    }}
                                    className="p-1 text-red-500 hover:bg-red-50 rounded-full"
                                  >
                                    <Trash className="w-4 h-4" />
                                  </button>
                                )}
                              </div>
                            </div>

                            {/* Collapsible Content */}
                            {isExpanded && (
                              <div className="px-4 pb-4 space-y-4 border-t border-gray-100">
                                <input
                                  type="text"
                                  placeholder="Enter text"
                                  className="w-full p-2 border rounded mt-4"
                                  value={textConfig.text}
                                  onChange={(e) =>
                                    updateTextConfig(index, {
                                      text: e.target.value,
                                    })
                                  }
                                />

                                <div className="flex gap-2">
                                  <Select
                                    value={textConfig.font}
                                    onValueChange={(value) => {
                                      const newFont =
                                        fonts.find((f) => f.family === value) ||
                                        fonts[0];
                                      updateTextConfig(index, {
                                        font: value,
                                        fontWeight: newFont.weights?.bold
                                          ? textConfig.fontWeight
                                          : "normal",
                                        fontStyle: newFont.weights?.italic
                                          ? textConfig.fontStyle
                                          : "normal",
                                      });
                                    }}
                                    options={fonts.map((font) => ({
                                      value: font.family,
                                      label: font.name,
                                      style: { fontFamily: font.family },
                                    }))}
                                    className="w-full"
                                    style={{ fontFamily: textConfig.font }}
                                  />

                                  <Select
                                    value={textConfig.fontWeight}
                                    onValueChange={(value) =>
                                      updateTextConfig(index, {
                                        fontWeight: value,
                                      })
                                    }
                                    options={[
                                      { value: "normal", label: "Regular" },
                                      ...(selectedFont.weights?.bold
                                        ? [{ value: "bold", label: "Bold" }]
                                        : []),
                                    ]}
                                    className="w-full"
                                  />
                                </div>

                                <div className="space-y-2">
                                  <label className="text-sm text-gray-600">
                                    Text Color
                                  </label>
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="color"
                                      value={textConfig.color}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          color: e.target.value,
                                        })
                                      }
                                      className="w-10 h-10 p-1 rounded cursor-pointer"
                                    />
                                    <input
                                      type="text"
                                      value={textConfig.color}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          color: e.target.value,
                                        })
                                      }
                                      className="flex-1 p-2 border rounded"
                                      pattern="^#[0-9A-Fa-f]{6}$"
                                      placeholder="#ffffff"
                                    />
                                  </div>
                                </div>

                                <div className="space-y-2">
                                  <label className="text-sm text-gray-600">
                                    Shadow Color
                                  </label>
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="color"
                                      value={textConfig.shadowColor}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          shadowColor: e.target.value,
                                        })
                                      }
                                      className="w-10 h-10 p-1 rounded cursor-pointer"
                                    />
                                    <input
                                      type="text"
                                      value={textConfig.shadowColor}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          shadowColor: e.target.value,
                                        })
                                      }
                                      className="flex-1 p-2 border rounded"
                                      pattern="^#[0-9A-Fa-f]{6}$"
                                      placeholder="#000000"
                                    />
                                  </div>
                                </div>

                                <div className="space-y-2">
                                  <label className="text-sm text-gray-600">
                                    Font Size
                                  </label>
                                  <div className="flex items-center gap-4">
                                    <input
                                      type="range"
                                      min="10"
                                      max="300"
                                      className="flex-1"
                                      value={textConfig.fontSize}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          fontSize: Number(e.target.value),
                                        })
                                      }
                                    />
                                    <input
                                      type="number"
                                      min="10"
                                      max="300"
                                      className="w-20 p-2 border rounded"
                                      value={textConfig.fontSize}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          fontSize: Number(e.target.value),
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="space-y-2">
                                  <label className="text-sm text-gray-600">
                                    X Position (%)
                                  </label>
                                  <div className="flex items-center gap-4">
                                    <input
                                      type="range"
                                      min="0"
                                      max="100"
                                      className="flex-1"
                                      value={textConfig.x}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          x: Number(e.target.value),
                                        })
                                      }
                                    />
                                    <input
                                      type="number"
                                      min="0"
                                      max="100"
                                      className="w-20 p-2 border rounded"
                                      value={textConfig.x}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          x: Number(e.target.value),
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="space-y-2">
                                  <label className="text-sm text-gray-600">
                                    Y Position (%)
                                  </label>
                                  <div className="flex items-center gap-4">
                                    <input
                                      type="range"
                                      min="0"
                                      max="100"
                                      className="flex-1"
                                      value={textConfig.y}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          y: Number(e.target.value),
                                        })
                                      }
                                    />
                                    <input
                                      type="number"
                                      min="0"
                                      max="100"
                                      className="w-20 p-2 border rounded"
                                      value={textConfig.y}
                                      onChange={(e) =>
                                        updateTextConfig(index, {
                                          y: Number(e.target.value),
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}

                      <button
                        onClick={addTextConfig}
                        className="w-full py-2 border-2 border-dashed border-gray-200 rounded-lg flex items-center justify-center gap-2 text-gray-500 hover:border-gray-300 hover:text-gray-700 transition-colors"
                      >
                        <Plus className="w-5 h-5" />
                        Add Text Element
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
